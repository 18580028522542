var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('setting-layout',{attrs:{"title":_vm.$t('pages.messages.title'),"handle-back-by-emit":true},on:{"back":function($event){return _vm.$router.push({ name: 'user.own.profile.view' })}}},[_c('v-container',{staticClass:"pt-0"},[(!_vm.user.verifyOndatoAt)?_c('v-row',{staticClass:"mt-3",on:{"click":_vm.handleClickTurnOff}},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-checkbox',{staticClass:"pt-0 ma-0",attrs:{"hide-details":"","dense":"","color":"orange darken-3","disabled":_vm.isDisabledTurnOff},on:{"change":_vm.handleTurnOff},model:{value:(_vm.turnOff),callback:function ($$v) {_vm.turnOff=$$v},expression:"turnOff"}})],1),_c('v-col',{attrs:{"cols":"10"}},[_vm._v(" "+_vm._s(_vm.$t("pages.messages.turn_off_direct_messages"))+" ")])],1):_vm._e(),_c('v-row',[_c('v-col',[_c('text-field',{staticClass:"search-field",attrs:{"placeholder":_vm.$t('pages.messages.search')},on:{"keyup":_vm.onChangeSearch},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-magnify")])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('router-link',{attrs:{"to":{ name: 'user.own.channels' }}},[_c('div',{staticClass:"accent-background px-4 py-2 my-2",staticStyle:{"display":"flex","align-items":"center","justify-content":"space-between","border-radius":"16px"}},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('icon-signal',{staticClass:"mr-2"}),_c('div',[_vm._v("Broadcasts")])],1),_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[(_vm.unreadBroadcastsMessagesCount)?_c('v-badge',{attrs:{"content":_vm.unreadBroadcastsMessagesCount,"offset-x":24,"offset-y":10,"color":"#FE4811"}}):_vm._e(),_c('v-icon',{attrs:{"size":"33"}},[_vm._v("mdi-chevron-right")])],1)])]),_vm._l((_vm.messages.filter(
                function (chat) { return !chat.isBroadcastingChannel; }
            )),function(item,i){return _c('div',{directives:[{name:"touch",rawName:"v-touch",value:({
                right: function () { return _vm.swipeMessage('R', i); },
                left: function () { return _vm.swipeMessage('L', i); },
            }),expression:"{\n                right: () => swipeMessage('R', i),\n                left: () => swipeMessage('L', i),\n            }"}],key:i,staticClass:"message-container"},[(item.messageCount > 0)?_c('v-row',{class:{ deletec: true, delete: _vm.deleteItem === i },attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('router-link',{attrs:{"to":{
                            name: 'otherUser.profile.viewSlug',
                            params: {
                                slug: item.slug,
                                from: 'user.own.messages',
                            },
                        }}},[_c('profile-image',{attrs:{"src":item.image,"username":item.name}})],1)],1),_c('v-col',{attrs:{"cols":"8"}},[_c('router-link',{attrs:{"to":{
                            name: 'user.own.chat',
                            params: { id: item.user_id },
                        }}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticStyle:{"display":"flex","align-items":"center"}},[(item.unread_messages > 0)?_c('v-badge',{attrs:{"content":item.unread_messages,"offset-x":-4,"offset-y":10,"color":"#FE4811"}},[_c('span',{staticClass:"name-sender font-weight-bold"},[_vm._v(" "+_vm._s(item.name)+" ")])]):_c('span',{staticClass:"name-sender"},[_vm._v(" "+_vm._s(item.name)+" ")])],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('span',{staticClass:"text-message",class:item.unread_messages > 0 ? 'font-weight-bold' : ''},[_vm._v(" "+_vm._s(_vm.text(item.text))+" ")])])],1)],1)],1),_c('v-spacer')],1):_vm._e(),_c('app-transition',[(_vm.deleteItem === i && !item.isBroadcastingChannel)?_c('v-row',{staticClass:"delete-icon-container",attrs:{"align":"center"}},[_c('v-spacer'),_c('v-col',{staticClass:"delete-icon-background",attrs:{"cols":"4"},on:{"click":function($event){return _vm.deleteChat(i, item.id)}}},[_c('app-icon',{staticClass:"deleteIcon",attrs:{"icon":"delete"}}),_c('span',{staticClass:"delete-text d-block text-capitalize"},[_vm._v(" "+_vm._s(_vm.$t("dialogs.delete"))+" ")])],1)],1):_vm._e()],1)],1)})],2),(this.isShowVerifyDialog)?_c('verify-dialog',{on:{"close":_vm.handleCloseVerifyDialog}}):_vm._e(),(this.isShowChangePriceDialog)?_c('change-price-dialog',{on:{"close":_vm.handleCloseChangePriceDialog}}):_vm._e(),(this.isShowNoodzlersCantTurnOffMessagesDialog)?_c('noodzlers-cant-turn-off-messages',{on:{"close":_vm.handleCloseNoodzlersCantTurnOffMessagesDialog}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }